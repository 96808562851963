import React from "react";

// `onClick`, `href`, and `ref` need to be passed to the DOM element
// for proper handling
const NavItem = React.forwardRef(
  ({ onClick, href, children, ...props }, ref) => {
    return (
      <a
        href={href}
        onClick={onClick}
        ref={ref}
        className="navbar-item"
        {...props}
      >
        {children}
      </a>
    );
  }
);

export default NavItem;
