import { Message, Section, Columns } from "react-bulma-components";

import { COLORS } from "../../constants";

const ErrorMessage = ({ error }) => (
  <Section>
    <Columns>
      <Columns.Column size={6} offset={3}>
        <Message color={COLORS.WARNING}>
          <Message.Header>Error</Message.Header>
          <Message.Body>
            Sorry, there was an error.
            <br />
            <br />
            <pre>{error.message}</pre>
            <br />
            <br />
            Please contact support if you need help.
          </Message.Body>
        </Message>
      </Columns.Column>
    </Columns>
  </Section>
);

export default ErrorMessage;
