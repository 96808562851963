import { useContext, useState } from "react";
import { Navbar as Nav, Container, Image, Level } from "react-bulma-components";
import Link from "next/link";

import NavItem from "./utils/ForwardRefNavItem";
import Icon from "./Icon";

import { useAuth } from "./../lib/use-auth";
import { colors, BREAKPOINTS } from "../constants";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const { user, signout } = useAuth();

  return (
    <Nav active={active} color={colors.white}>
      <Container>
        <Nav.Brand>
          <Link href={"/"} passHref>
            <NavItem>BountyHiring</NavItem>
          </Link>
          <Nav.Burger onClick={() => setActive(!active)}></Nav.Burger>
        </Nav.Brand>
        <Nav.Menu onClick={() => setActive(false)}>
          <div className="navbar-start">
            <Link href={"/jobs"} passHref>
              <NavItem>Jobs</NavItem>
            </Link>
            <Link href="/calculator/how-much-can-earn" passHref>
              <NavItem title="Estimate Earnings Calculator">
                <Icon icon="calculator" />
                &nbsp;Estimate Earnings
              </NavItem>
            </Link>
          </div>
          <div className="navbar-end">
            <Nav.Item href={"https://blog.bountyhiring.com"}>Blog</Nav.Item>

            <Nav.Item dropdown hoverable href="#">
              <Nav.Link arrowless={false}>Contact Us</Nav.Link>
              <Nav.Dropdown>
                <Link href={"/support"} passHref>
                  <NavItem href="#">Support</NavItem>
                </Link>
                <Nav.Item
                  href={
                    "https://blog.bountyhiring.com/frequently-asked-questions/"
                  }
                >
                  FAQs
                </Nav.Item>
                <Level breakpoint={BREAKPOINTS.MOBILE} className="nav-social">
                  <Nav.Item href={"https://www.facebook.com/BountyHiring/"}>
                    <Icon icon={["fab", "facebook"]} faSize="2x" />
                  </Nav.Item>
                  <Nav.Item
                    href={"https://www.linkedin.com/company/bountyhiring"}
                  >
                    <Icon icon={["fab", "linkedin"]} faSize="2x" />
                  </Nav.Item>
                  <Nav.Item href={"https://twitter.com/BountyHiring"}>
                    <Icon icon={["fab", "twitter-square"]} faSize="2x" />
                  </Nav.Item>
                </Level>
              </Nav.Dropdown>
            </Nav.Item>
            {!user && (
              <Link href={"/login"} passHref>
                <NavItem>Sign Up</NavItem>
              </Link>
            )}
            {!user && (
              <Link href={"/login"} passHref>
                <NavItem>Sign In</NavItem>
              </Link>
            )}
            {user && (
              <Nav.Item dropdown={true} hoverable={true} href="#">
                <Nav.Link arrowless={false}>
                  {(user && user.displayName) || "Profile"}
                </Nav.Link>
                <Nav.Dropdown>
                  <Link href={"/user/profile"} passHref>
                    <NavItem>Profile</NavItem>
                  </Link>
                  <Link href={"/companies/admin/"} passHref>
                    <NavItem>Post a job</NavItem>
                  </Link>
                  <Link href={"/companies/admin/"} passHref>
                    <NavItem>Manage your Jobs</NavItem>
                  </Link>
                  <Nav.Item onClick={signout}>Logout</Nav.Item>
                </Nav.Dropdown>
              </Nav.Item>
            )}
          </div>
        </Nav.Menu>
      </Container>
    </Nav>
  );
};

export default Navbar;
