import {
  Footer,
  Container,
  Content,
  Level,
  Heading,
  Columns
} from "react-bulma-components";
import Icon from "./Icon";
import Link from "next/link";
import { colors, BREAKPOINTS } from "../constants";
import CookieConsent, { Cookies } from "react-cookie-consent";

const FooterComponent = () => (
  <Footer className="my-footer">
    <Container>
      <Content style={{ textAlign: "center" }}>
        <Columns>
          <Columns.Column size="one-third">
            <p subtitle="true">Policies</p>
            <Link href="/policies/privacy">
              <a>Privacy Policy</a>
            </Link>
            <br />
            <br />
            <Link href="/policies/terms-of-service">
              <a>Terms of Service</a>
            </Link>
            <br />
            <br />
            <Link href="/policies/cookies">
              <a>Cookie Policy</a>
            </Link>
          </Columns.Column>

          <Columns.Column size="one-third">
            <Level breakpoint={BREAKPOINTS.MOBILE} className="nav-social">
              <a href={"https://www.facebook.com/BountyHiring/"}>
                <Icon icon={["fab", "facebook"]} faSize="2x" />
              </a>
              <a href={"https://www.linkedin.com/company/bountyhiring"}>
                <Icon icon={["fab", "linkedin"]} faSize="2x" />
              </a>
              <a href={"https://twitter.com/BountyHiring"}>
                <Icon icon={["fab", "twitter-square"]} faSize="2x" />
              </a>
            </Level>

            <p>
              Made in Estonia.
              <br />
              <br />
              <strong>&copy; 2020 Radical Software OÜ</strong>
              <br />
              Tallinn, Estonia
              <br />
            </p>
          </Columns.Column>
          <Columns.Column size="one-third">
            <p subtitle="true">Resources</p>
            <Link href="/calculator/how-much-can-earn" passHref>
              <a>Calculate your network value</a>
            </Link>
            <br />
            <br />
            <a href="https://blog.bountyhiring.com">Blog</a>
          </Columns.Column>
        </Columns>

        <CookieConsent
          buttonStyle={{
            marginRight: "100px"
          }}
          location="bottom"
        >
          This website uses cookies to enhance the user experience. Read our{" "}
          <Link href="/policies/cookies/" passHref>
            <a>cookie policy</a>
          </Link>{" "}
          for more details.
        </CookieConsent>
      </Content>
    </Container>
  </Footer>
);

export default FooterComponent;
