import React, { Fragment } from "react";
import NextApp from "next/app";
import "isomorphic-fetch";

import Navbar from "../components/Navbar";

import "../styles/_variables.sass";
import "../styles/styles.scss";
// import { removeLocalCreds } from "../utils/index";
import {
  DEFAULT_SIGNIN_REDIRECT,
  UNDEFINED,
  LOCAL_STORAGE_TOKEN,
  SIGNIN_URL,
  FRONTEND
} from "../constants";
import Footer from "../components/Footer";
import BULMA_CONSTANTS from "react-bulma-components/lib/constants";
import Router from "next/router";
import ErrorMessage from "../components/utils/ErrorMessage";
import NProgress from "nprogress";
import Head from "next/head";
import { DefaultSeo } from "next-seo";
import { ProvideAuth } from "./../lib/use-auth";
import { FuegoProvider } from "@nandorojo/swr-firestore";
import { fuego } from "./../lib/firebase";
import moment from "moment";
import * as Sentry from "@sentry/node";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  });
}

if (typeof window !== UNDEFINED) {
  moment.locale(navigator.language);
}

Router.events.on("routeChangeStart", url => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", url => {
  // if (GA_AVAILABLE && gtag) {
  //   gtag("config", `${process.env.GA_KEY}`, {
  //     path_path: url
  //   });
  // }
  NProgress.done();
});
Router.events.on("routeChangeError", (err, url) => {
  // if (GA_AVAILABLE && gtag) {
  //   gtag("config", `${process.env.GA_KEY}`, {
  //     path_path: url
  //   });
  // }
  NProgress.done();
});

const { BREAKPOINTS } = BULMA_CONSTANTS;

/**
 * if server, send to login page, regardless because there are no cookies on next js server
 * if browser and no token, send to login page
 * if token, verify and refresh it
 * return: token;
 */
export const authorize = async asPath => {
  // get last url and pass in as next
  const redirect_url = `${SIGNIN_URL}?next=${asPath}`;

  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  if (!token) {
    Router.push(redirect_url);
    return;
  }

  return token;
};

// Todos
// subscribe to auth
// on change, if no user exists, redirect to SignIn page
// if no change, nothing needs to be done
// if user newly set, store it in App.state and pass it down to children

const MyApp = ({ Component, pageProps, err }) => {
  if (Component.isThumbnail) {
    return (
      <Fragment>
        <Head>
          <style
            dangerouslySetInnerHTML={{
              __html: `
              body {
                background-image: url("/subtle_dots/subtle_dots.png");
                background-repeat: tile;
                // background-size: 100px 100px;
                height: 100vh;
                // display: flex;
                // text-align: center;
                // align-items: center;
                // justify-content: center;
              }
            `
            }}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1"
            key="viewport"
          />
        </Head>
        <div className="Site-content">
          {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
          <Component {...pageProps} err={err} />
        </div>
      </Fragment>
    );
  }
  // return
  return (
    <Fragment>
      <Head>
        {/* Import CSS for nprogress */}
        <link rel="stylesheet" type="text/css" href="/nprogress.css" />
      </Head>
      <DefaultSeo
        title="Refer Friends And Earn Bounty"
        description="Earn referral bounty for referring successful candidates to jobs."
        titleTemplate="%s | BountyHiring"
        openGraph={{
          type: "website",
          url: `${FRONTEND}`,
          title: "BountyHiring",
          images: [
            {
              alt: "BountyHiring: Refer friends and earn bounty",
              url: `${FRONTEND}/images/facebookCover.png`,
              width: 820,
              height: 312
            }
          ],
          description:
            "Earn referral bounty for referring successful candidates to jobs."
        }}
      />
      <div className="Site-content">
        <ProvideAuth>
          <FuegoProvider fuego={fuego}>
            <Navbar />
            {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
            <Component {...pageProps} err={err} />
          </FuegoProvider>
        </ProvideAuth>
      </div>
      <Footer />
    </Fragment>
  );
};

// export default class App extends NextApp {
//   static getInitialProps = async ({ Component, router, ctx }) => {
//     const pageQuery = ctx.query;
//     const { asPath } = ctx;

//     if (Component.isThumbnail) {
//       return {
//         pageQuery
//       };
//     }

//     const csr = !!Component.csr;

//     const { variables, cacheConfig } = Component.getInitialProps
//       ? await Component.getInitialProps(ctx)
//       : {};

//     try {
//       // SSR
//       if (!csr && initEnvironment && Component.query) {
//         // prefetch data for SSR
//         const { environment, relaySSR } = await initEnvironment();
//         await fetchQuery(environment, Component.query, variables, cacheConfig);

//         return {
//           variables,
//           cacheConfig,
//           pageQuery,
//           relayData: await relaySSR.getCache(),
//           asPath
//         };
//       }
//     } catch (e) {
//       console.error(e);
//     }

//     return {
//       variables,
//       cacheConfig,
//       pageQuery,
//       asPath
//     };
//   };

//   render() {
//     const {
//       Component,
//       variables = {},
//       cacheConfig,
//       relayData,
//       pageQuery,
//       asPath
//     } = this.props;

//     const csr = !!Component.csr;
//     if (csr && typeof window !== UNDEFINED) {
//       authorize(asPath);
//     }
//     const canRender = !csr || (csr && typeof window !== UNDEFINED);

//     const environment = createEnvironment(
//       relayData,
//       JSON.stringify({
//         queryID: Component.query ? Component.query().params.name : undefined,
//         variables
//       })
//     );

//   }
// }

export default MyApp;
